import { userAxios } from '@/services'

const surveyList = async (perPage) => {
    try {
        return await userAxios.get(`survey-admin/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const surveyUpdate = async (id, data) => {
    try {
        return await userAxios.put(`survey-admin/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const surveyPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`survey-admin/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const surveyFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`survey-admin/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const surveyFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`survey-admin/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    surveyList,
    surveyUpdate,
    surveyPagination,
    surveyFilter,
    surveyFilterPagination
}